import { httpClient } from '../../axios-wrapper/httpClient'
import { BusinessServicerResponseType } from '../../models/servicer'
import { ServicerDetails, AddOrUpdateServicer } from '../../urls/servicer'

export const getServicerDetails = async (
  servicerId: string | undefined
): Promise<BusinessServicerResponseType> => {
  const response = await httpClient.get(
    `${ServicerDetails}?servicerId=${servicerId}`
  )

  return response.data
}

export const postAddOrUpdateServicer = async (
  request: any | undefined
): Promise<any> => {
  const form = new FormData()

  const dateProperties = [
    'insurance_ExpirationDate',
    'dateOfEstablishment',
    'membershipEstablished',
    'onSiteAudit',
  ]
  for (const key in request.servicer) {
    if (request.servicer[key] !== null) {
      if (dateProperties.find((f) => f === key)) {
        form.append(key, new Date(request.servicer[key]).toISOString())
      } else if (key === 'daysOfOperation') {
        form.append('daysOfOperationString', request.servicer[key])
        form.append(key, request.servicer[key])
      } else {
        form.append(key, request.servicer[key])
      }
    }
  }
  const response = await httpClient.post(AddOrUpdateServicer, form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response?.data
}
