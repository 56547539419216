import React, { useContext, useEffect, useState } from 'react'
import { Tabs, Tab, Box } from '@mui/material'
import { BusinessServicerRequestType } from 'src/infra/api/models/servicer'
import { useQuery } from '@apollo/client'
import { GetClient } from 'src/graphql/models/clientProfiles'
import { GET_CLIENT } from 'src/graphql/operations/queries/clientProfiles'
import ServicerBusinessInformation from './ServicerBusinessInformation'
import { Theme } from '@mui/system'
import shadows from '@mui/material/styles/shadows'
import { makeStyles } from '@mui/styles'
import { useHistory, useLocation } from 'react-router-dom'
import { AuthContext } from 'src/context/AuthenticationContext'
import { TENANT_TYPE } from 'src/utils/constants'
import CustomerAuditInfo from 'src/components/Audit/CustomerAuditInfo'
import Comment from 'src/components/Comment'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      style={{ width: '100%', padding: 8 }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

TabPanel.defaultProps = {
  children: null,
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    boxShadow: shadows[1],
    display: 'flex',
    minHeight: 224,
  },
  tabs: {
    minWidth: 160,
    borderRight: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    textAlign: 'center',
  },
}))

interface ServicerBusinessTabProps {
  clientId: number
  servicerBusinessId?: string | any
  servicerData?: BusinessServicerRequestType | any
  servicerLoading?: boolean
}
const ServicerBusinessTab: React.FC<ServicerBusinessTabProps> = ({
  clientId,
  servicerBusinessId,
  servicerData,
  servicerLoading,
}) => {
  const { user } = useContext(AuthContext)
  const isInternal = user && user.profile[TENANT_TYPE] === 'internal'
  const classes = useStyles()
  const [value, setValue] = useState(0)
  const { data: getClientData } = useQuery<{
    getClient: GetClient
  }>(GET_CLIENT, { variables: { clientId: Number(clientId) } })

  const location = useLocation()
  const history = useHistory()

  const tabIndices: { [key: string]: number } = {
    'business-information': 0,
    comments: 1,
    audit: 2,
  }
  useEffect(() => {
    const tabText =
      location.pathname.split('/').pop()?.replace('tab=', '')?.trim() ||
      'business-information'
    const tabIndex = tabIndices[tabText]
    if (tabIndex !== undefined) {
      setValue(tabIndex)
    } else {
      setValue(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
    const tabText =
      Object.keys(tabIndices).find((key) => tabIndices[key] === newValue) ||
      'business-information'

    const currentPathname = location.pathname
    const newSearch = `/tab=${tabText}`
    const newUrl = currentPathname.includes('tab=')
      ? currentPathname.replace(/\/tab=[^&]+/, newSearch)
      : currentPathname + newSearch
    history.push(newUrl)
  }

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Servicer Business Profile"
        className={classes.tabs}
      >
        <Tab label="Business information (Servicer)" {...a11yProps(0)} />
        {isInternal && (
          <Tab
            label="Comments"
            {...a11yProps(1)}
            disabled={!clientId || !servicerBusinessId}
          />
        )}
        {isInternal && (
          <Tab label="Audit" {...a11yProps(2)} disabled={!servicerBusinessId} />
        )}
      </Tabs>
      <TabPanel value={value} index={0}>
        <ServicerBusinessInformation
          clientId={clientId}
          servicerBusinessId={servicerBusinessId}
          servicerData={servicerData}
          client={getClientData?.getClient}
          servicerLoading={servicerLoading}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {clientId && servicerBusinessId && (
          <Comment clientId={clientId} businessId={servicerBusinessId} />
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {!!servicerBusinessId && (
          <CustomerAuditInfo id={servicerBusinessId} tableName="business" />
        )}
      </TabPanel>
    </div>
  )
}

ServicerBusinessTab.defaultProps = {
  servicerBusinessId: null,
  servicerData: null,
  servicerLoading: false,
}

export default ServicerBusinessTab
