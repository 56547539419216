import { BACKEND_URL } from '../../axios-wrapper/httpClient'

const clientPrefix = '/client.'

export const GetAssetTypes = `${BACKEND_URL}${clientPrefix}getAssetTypes`
export const GetBuyersRelatedToSeller = `${BACKEND_URL}${clientPrefix}getBuyersRelatedToSeller`
export const SaveAgreementTemplate = `${BACKEND_URL}${clientPrefix}saveAgreementTemplate`
export const ArchiveAgreementTemplate = `${BACKEND_URL}${clientPrefix}archiveAgreementTemplate`
export const UpdateAgreementTemplate = `${BACKEND_URL}${clientPrefix}updateAgreementTemplate`
export const GetDepartments = `${BACKEND_URL}${clientPrefix}getDepartments`
export const GetBusinessLicenses = `${BACKEND_URL}${clientPrefix}getBusinessLicenses`
