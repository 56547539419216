import React, { useState } from 'react'
import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Skeleton,
  Switch,
  TextField,
} from '@mui/material'
import hardCodeData from 'src/utils/hardcodeData'
import { Archive } from '@mui/icons-material'
import { State } from '@progress/kendo-data-query'
import { renderListJoined } from 'src/utils/formatKendoColumns'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import {
  getAgreementTemplates,
  updateAgreementTemplateFeatureBusiness,
} from 'src/infra/api/services/agreementTemplates'
import { AgreementTemplateResponseType } from 'src/infra/api/models/agreementTemplates'
import {
  Button,
  Colors,
  DataTable,
  ModalDialog,
  Typography,
  renderDate,
  renderYesOrNoBoolean,
} from 'everchain-uilibrary'
import { useHistory } from 'react-router-dom'
import { AGGREMENT_TEMPLATE_DETAIL, AGGREMENT_TEMPLATE_FORM } from 'src/routes'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { notistackOptions } from 'src/configs/notistackOptions'
import { enqueueSnackbar } from 'notistack'
import { archiveAgreementTemplate } from 'src/infra/api/services/client'
import { getBusinessFeatureFlag } from 'src/infra/api/services/business'
import { BusinessFeatureFlagResponse } from 'src/infra/api/models/business'

interface AgreementTemplateFeatureFlag {
  enableBOSAgreementTemplate: boolean
  enablePSAAgreementTemplate: boolean
  enableNDAAgreementTemplate: boolean
  allowUploadPSADuringBid: boolean
}

interface AgreementTemplatesProps {
  sellerId?: string
  clientId?: number
  tabSelected?: string
}

const AgreementTemplatesList = ({
  sellerId,
  clientId,
  tabSelected,
}: AgreementTemplatesProps) => {
  const [totalDataSize, setTotalDataSize] = useState<number>(0)
  const [currentItem, setCurrentItem] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [agreementTemplateFeatureFlag, setAgreementTemplateFeatureFlag] =
    useState<AgreementTemplateFeatureFlag | undefined>()
  const [openConfirmationModal, setOpenConfirmationModal] =
    useState<boolean>(false)

  const [type, setType] = useState<'psa' | 'bos' | 'nda'>('psa')
  const [reason, setReason] = useState<string>('')
  const [gridState, setGridState] = useState<State>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })
  const handleType = (e: any) => {
    setType(e?.target?.value)
  }

  const gridColumns: any[] = [
    {
      field: 'name',
      title: 'Name',
      width: 180,
      show: true,
    },
    {
      field: 'assignedAssetTypes',
      title: 'Assigned Asset Type',
      width: 180,
      show: type !== 'nda',
      render: (props: any) => {
        return renderListJoined(
          props.dataItem.assignedAssetTypes,
          props.dataItem.allAssetTypeSelected,
          'All'
        )
      },
    },
    {
      field: 'buyerAccess',
      title: 'Buyer',
      width: 180,
      show: type !== 'nda',
      render: (props: any) => {
        return renderListJoined(
          props.dataItem.buyerNames,
          props.dataItem.allBuyersSelected,
          'All'
        )
      },
    },
    {
      field: 'archivedDate',
      title: 'Archive Date',
      width: 115,
      show: true,
      render: renderDate,
    },
    {
      field: 'archiveReason',
      title: 'Archive Reason',
      width: 130,
      show: true,
    },
    {
      field: 'creationDate',
      title: 'Created at',
      width: 130,
      show: true,
      render: renderDate,
    },
    {
      field: 'useAsCustomPsa',
      title: 'Is Custom PSA',
      width: 120,
      show: type === 'psa',
      render: renderYesOrNoBoolean,
    },
    {
      field: '',
      title: 'Archive',
      width: 100,
      show: true,
      render: (props: any) => (
        <td>
          {!props.dataItem.archivedDate ? (
            <center>
              <IconButton
                onClick={() => {
                  setCurrentItem(props.dataItem.id)
                  setOpenConfirmationModal(true)
                }}
                size="small"
              >
                <Archive />
              </IconButton>
            </center>
          ) : (
            <span>
              <center>Archived </center>
            </span>
          )}
        </td>
      ),
    },
  ]
  const { data: agreementTemplate, isFetching: agreementLoading } =
    useCustomQuery<AgreementTemplateResponseType>(
      ['getAgreementTemplates', type, gridState],
      async () => {
        return getAgreementTemplates(
          type,
          sellerId,
          JSON.stringify(gridState)
        ).then((result: AgreementTemplateResponseType) => {
          setTotalDataSize(result.total)
          return result
        })
      },
      {
        enabled: true,
        cacheTime: 0,
      }
    )
  const { isFetching } = useCustomQuery<BusinessFeatureFlagResponse>(
    ['getBusinessFeatureFlag', sellerId],
    async () => {
      return getBusinessFeatureFlag(sellerId).then((result) => {
        setAgreementTemplateFeatureFlag(result)
        setIsLoading(false)
        return result
      })
    },
    {
      enabled: !!sellerId,
      cacheTime: 0,
    }
  )
  const reactQueryClient = useQueryClient()
  const history = useHistory()
  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')
  const archiveAgreementTemplateEvent = useMutation({
    mutationFn: (request: any) =>
      archiveAgreementTemplate(request.id, request.reason),
    onSuccess: () => {
      setCurrentItem(0)
      setIsLoading(false)
      setOpenConfirmationModal(false)
      enqueueSnackbar(
        'Agreement template archived successfully.',
        notifySuccess
      )
      reactQueryClient.refetchQueries({
        queryKey: ['getAgreementTemplates'],
      })
    },
    onError: () => {
      setCurrentItem(0)
      setOpenConfirmationModal(false)
      setIsLoading(false)
      enqueueSnackbar('Error when archive Agreement template.', notifyError)
    },
  })

  const updateAgreementTemplateFeatureBusinessEvent = useMutation({
    mutationFn: (request: any) =>
      updateAgreementTemplateFeatureBusiness(
        request.sellerId,
        request.enableBOSAgreementTemplate,
        request.enablePSAAgreementTemplate,
        request.enableNDAAgreementTemplate,
        request.allowUploadPSADuringBid
      ),
    onSuccess: () => {
      setIsLoading(false)
      enqueueSnackbar('Use feature changed successfully.', notifySuccess)
      reactQueryClient.refetchQueries({
        queryKey: ['getBusinessFeatureFlag'],
      })
    },
    onError: () => {
      setIsLoading(false)
      enqueueSnackbar('Error when change Use feature.', notifyError)
    },
  })

  const valueFeatureFlag = {
    psa: agreementTemplateFeatureFlag?.enablePSAAgreementTemplate,
    bos: agreementTemplateFeatureFlag?.enableBOSAgreementTemplate,
    nda: agreementTemplateFeatureFlag?.enableNDAAgreementTemplate,
  }[type]

  const onChangeFeatureFlagSwitch = (e: any) => {
    const updatedFlags = {
      enablePSAAgreementTemplate:
        type === 'psa'
          ? e.target.checked
          : agreementTemplateFeatureFlag?.enablePSAAgreementTemplate,
      enableBOSAgreementTemplate:
        type === 'bos'
          ? e.target.checked
          : agreementTemplateFeatureFlag?.enableBOSAgreementTemplate,
      enableNDAAgreementTemplate:
        type === 'nda'
          ? e.target.checked
          : agreementTemplateFeatureFlag?.enableNDAAgreementTemplate,
      allowUploadPSADuringBid:
        agreementTemplateFeatureFlag?.allowUploadPSADuringBid,
    }

    updateAgreementTemplateFeatureBusinessEvent.mutate({
      sellerId,
      ...updatedFlags,
    })
  }

  const onChangeAllowUploadPSADuringBid = (e: any) => {
    const updatedFlags = {
      enablePSAAgreementTemplate:
        agreementTemplateFeatureFlag?.enablePSAAgreementTemplate,
      enableBOSAgreementTemplate:
        agreementTemplateFeatureFlag?.enableBOSAgreementTemplate,
      enableNDAAgreementTemplate:
        agreementTemplateFeatureFlag?.enableNDAAgreementTemplate,
      allowUploadPSADuringBid: e.target.checked,
    }

    updateAgreementTemplateFeatureBusinessEvent.mutate({
      sellerId,
      ...updatedFlags,
    })
  }

  return (
    <Box mb={12}>
      <Typography variant="h6">Agreement Templates</Typography>
      <Grid mt={2} container spacing={4}>
        <Grid item xs={12} lg={2}>
          {!isLoading && !isFetching ? (
            <TextField
              fullWidth
              label="Type"
              select
              name="type"
              onChange={handleType}
              value={type}
            >
              {hardCodeData.getAgreementTemplates().map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          ) : (
            <Skeleton
              style={{ marginTop: '10px' }}
              height={40}
              width={150}
            ></Skeleton>
          )}
        </Grid>

        <Grid marginTop={4} item xs={12} lg={2}>
          {!isLoading && !isFetching ? (
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  onChange={(e) => {
                    setIsLoading(true)
                    onChangeFeatureFlagSwitch(e)
                  }}
                  name="isFeatureEnable"
                  checked={valueFeatureFlag}
                  value={valueFeatureFlag}
                  disabled={isFetching || isLoading}
                />
              }
              label="Use feature"
            />
          ) : (
            <Skeleton style={{ marginTop: '-2px' }} height={40} width={150} />
          )}
        </Grid>
        <Grid marginTop={4} item xs={12} lg={4}>
          {!isLoading && !isFetching ? (
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  onChange={(e) => {
                    setIsLoading(true)
                    onChangeAllowUploadPSADuringBid(e)
                  }}
                  name="allowUploadPSADuringBid"
                  checked={
                    agreementTemplateFeatureFlag?.allowUploadPSADuringBid
                  }
                  value={agreementTemplateFeatureFlag?.allowUploadPSADuringBid}
                  disabled={isFetching || isLoading}
                />
              }
              label="Allow buyers to upload psa draft during bid"
            />
          ) : (
            <Skeleton style={{ marginTop: '-2px' }} height={40} width={150} />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          lg={4}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Button
            onClick={() => {
              history.push(
                `${AGGREMENT_TEMPLATE_FORM}/${sellerId}/${clientId}/tab=${tabSelected}`
              )
            }}
            height={40}
            width={100}
            disabled={
              type === 'nda' &&
              agreementTemplate?.data.some((x) => !x.archivedDate)
            }
          >
            New
          </Button>
        </Grid>
        <Grid item xs={12} lg={12}>
          <DataTable
            style={{
              cursor: 'pointer',
            }}
            isLoading={agreementLoading || isFetching || isLoading}
            isFetching={agreementLoading || isFetching || isLoading}
            gridColumns={gridColumns}
            gridState={gridState}
            data={agreementTemplate?.data}
            sortable
            pageable
            onRowClick={(row) => {
              history.push(
                `${AGGREMENT_TEMPLATE_DETAIL}/${row.dataItem.id}/${sellerId}/${clientId}/tab=${tabSelected}`
              )
            }}
            total={totalDataSize}
            onDataStateChange={(e: any) => {
              setGridState(e.dataState)
            }}
          />
        </Grid>
      </Grid>
      <ModalDialog
        header="Confirmation"
        isOpen={openConfirmationModal}
        onClose={() => {
          setOpenConfirmationModal(false)
          setReason('')
        }}
        isLoading={isLoading || isFetching}
        buttonOkText="Submit"
        buttonCancelText="Cancel"
        disableCancelButton={isLoading || isFetching}
        disableOkButton={isLoading || isFetching || !reason}
        onContinue={() => {
          setIsLoading(true)
          archiveAgreementTemplateEvent.mutate({
            id: currentItem,
            reason: reason,
          })
          setReason('')
        }}
      >
        <Typography isLoading={isLoading || isFetching}>
          Are you sure you want to archive this agreement template?
        </Typography>
        <Grid item xs={12} lg={3}>
          <TextField
            fullWidth
            label="Reason"
            name="reason"
            onChange={(e) => setReason(e.target.value)}
            value={reason}
            inputProps={{ maxLength: 500 }}
          ></TextField>
        </Grid>
      </ModalDialog>
    </Box>
  )
}

AgreementTemplatesList.defaultProps = {
  sellerId: null,
}
export default AgreementTemplatesList
