import { Box, Button, Typography } from '@mui/material'
import { Add, Delete, Edit } from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { AbilityContext } from 'src/context/Can'
import { useDeleteBusinessLicense } from 'src/graphql/operations/mutations/licenses'
import { getStateDropDownName } from 'src/utils/common'
import { PermissionCodeAccess } from 'src/utils/constants'
import License from '.'
import { Grid, ModalDialog, renderDate } from 'everchain-uilibrary'
import { State } from '@progress/kendo-data-query'
import {
  Grid as KendoGrid,
  GridColumn as Column,
} from '@progress/kendo-react-grid'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getBusinessLicenses } from 'src/infra/api/services/client'

import { useQueryClient } from '@tanstack/react-query'

interface BusinessLicensesListProps {
  businessId: string
  clientCountry?: string | null
  businessCountry?: string | null
}

const BusinessLicensesList = ({
  businessId,
  clientCountry,
  businessCountry,
}: BusinessLicensesListProps) => {
  const [showAddForm, setShowAddForm] = useState(false)
  const [licenseToEdit, setLicenseToEdit] = useState()
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false)
  const [licenseId, setLicenseId] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  const grid = useRef<any>(null)
  const queryClient = useQueryClient()

  const [gridState, setGridState] = useState<State>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })
  const ability = useContext(AbilityContext)

  const hasBasicInfoPermission = ability.can(
    PermissionCodeAccess.CLIENT_BASICINFO_PERMISSION,
    'any'
  )

  const {
    data: getLicensesData,
    isFetching: licensesLoading,
    error,
  } = useCustomQuery<any>(
    ['getBusinessLicenses', businessId, gridState],
    async () => getBusinessLicenses(businessId, gridState),
    { enabled: true, cacheTime: 0 }
  )

  const onRemove = (id: string) => {
    setLicenseId(id)
  }

  const handleDeleteLicenseCompleted = (data: any) => {
    if (data) {
      enqueueSnackbar('License removed successfully', {
        variant: 'success',
      })
    }
  }

  const { DeleteLicense, loading: licenseLoading } = useDeleteBusinessLicense(
    handleDeleteLicenseCompleted
  )

  const handleRemoveLicense = (license: string): void => {
    DeleteLicense({
      variables: {
        request: license,
      },
    })
    queryClient.refetchQueries({
      queryKey: ['getBusinessLicenses'],
    })
    setLicenseId('')
    setOpenRemoveDialog(false)
  }

  const handleClose = () => {
    setOpenRemoveDialog(false)
    setLicenseId('')
  }

  const handleRemove = () => {
    handleRemoveLicense(licenseId)
  }
  const country = process.env.REACT_APP_COUNTRY

  const dataColumns: any[] = [
    {
      title: getStateDropDownName(country),
      field: 'stateCode',
      show: true,
    },
    {
      title: 'Country',
      field: 'countryCode',
      show: true,
    },
    {
      title: 'City',
      field: 'city',
      show: true,
    },
    {
      title: 'Issued',
      field: 'issuedDate',
      render: renderDate,
      show: true,
    },
    {
      title: 'Expires',
      field: 'expirationDate',
      render: renderDate,
      show: true,
    },
    {
      title: 'Comments',
      field: 'comment',
      show: true,
    },
    {
      title: ' ',
      field: 'id',
      show: true,

      render: (props: any) => {
        return (
          <td>
            <Box flexDirection="row">
              <Button
                disableRipple={true}
                style={{ backgroundColor: 'transparent' }}
                disabled={licenseLoading || !hasBasicInfoPermission}
                startIcon={
                  <Box>
                    <Delete fontSize="small" />
                  </Box>
                }
                onClick={() => {
                  onRemove(props.dataItem['id'])
                }}
              />
              <Button
                disableRipple={true}
                style={{ backgroundColor: 'transparent' }}
                disabled={licenseLoading || !hasBasicInfoPermission}
                startIcon={
                  <Box>
                    <Edit fontSize="small" />
                  </Box>
                }
                onClick={() => {
                  setLicenseToEdit(props.dataItem)
                  setShowAddForm(true)
                }}
              />
            </Box>
          </td>
        )
      },
    },
  ]

  const handleLicenseFormToggle = () => {
    setShowAddForm(!showAddForm)
    setLicenseToEdit(undefined)
  }

  useEffect(() => {
    if (!openRemoveDialog && licenseId) {
      setOpenRemoveDialog(true)
    }
  }, [licenseId, openRemoveDialog])

  return (
    <>
      <Grid container>
        <Grid item lg={12}>
          <Box mb={4} textAlign="right">
            <Button
              variant="contained"
              color="primary"
              disabled={!hasBasicInfoPermission}
              startIcon={<Add />}
              onClick={() => {
                setShowAddForm(true)
              }}
            >
              Add License
            </Button>
          </Box>
        </Grid>

        <Grid item>
          <KendoGrid
            id="tbLicenses"
            ref={grid}
            data={getLicensesData?.licenseResponse || []}
            sortable
            pageable
            skip={gridState.skip}
            take={gridState.take}
            filter={gridState.filter}
            sort={gridState.sort}
            total={getLicensesData?.total}
            onDataStateChange={(e) => {
              setGridState(e.dataState)
            }}
          >
            {dataColumns.map(
              (column, idx) =>
                column.show && (
                  <Column
                    key={idx}
                    field={column.field}
                    title={column.title}
                    cell={column.render}
                  />
                )
            )}
          </KendoGrid>
        </Grid>
      </Grid>

      <License
        clientCountry={clientCountry}
        businessCountry={businessCountry}
        businessId={businessId}
        open={showAddForm}
        onClose={handleLicenseFormToggle}
        initialValue={licenseToEdit}
      />
      <ModalDialog
        isOpen={openRemoveDialog}
        header="Remove License"
        buttonCancelText="Cancel"
        buttonOkText="Continue"
        onClose={handleClose}
        onContinue={handleRemove}
        isFetching={licenseLoading}
      >
        <Box mb={3}>
          <Typography variant="body2" color="textSecondary">
            Are you sure you want to remove this license?
          </Typography>
        </Box>
      </ModalDialog>
    </>
  )
}
BusinessLicensesList.defaultProps = {
  clientCountry: null,
  businessCountry: null,
}

export default BusinessLicensesList
